<template >
  <div>
    <div class="app-modal__box" :class="mode ? '' : 'performance__night'">
      <div class="app-modal__in">
        <div class="app-modal__header d-flex f-between p-5 m-title-modal">
          <p class="large--title m-0" :class="mode ? 'text__day2' : 'text__night2'">
            {{
              $t("message.edit_m", {
                m: $t("message.department_y"),
              })
            }}
          </p>
          <div>
            <crm-store-update-close
              :permission="$options.name"
              :button_type="'store'"
              :loading="loadingButton"
              @c-submit="submit(true)"
              @c-close="close()"
            ></crm-store-update-close>
          </div>
        </div>
      </div>
      <!-- app-modal__header end -->

      <div class="app-modal__body p-5 pb-0">
        <div class="timeline-items__right rounded-sm w-100 p-4" :class="mode ? 'table__myday' : 'table__mynight'">
          <el-form ref="form" status-icon :model="form" :rules="rules">
            <el-row :gutter="20">
              <el-col :span="24">
                <div class="app-form__group mb-4">
                  <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                    {{ $t("message.name") }}
                  </span>
                  <el-form-item prop="name">
                    <crm-input
                      :inputValue="form.name"
                      size="medium"
                      v-model="form.name"
                      autocomplete="off"
                      :class="mode ? 'input__day' : 'input__night'"
                    >
                    </crm-input>
                  </el-form-item>
                </div>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </div>
      <!-- end app-modal__body -->
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";
import selectGraphic from "@/components/filters/inventory/select-graphic";
import selectDepartment from "@/components/filters/inventory/select-department";
import selectBranch from "@/components/filters/inventory/select-branch";
import show from "@/utils/mixins/show";
export default {
  mixins: [form, drawer, show],
  name: "DepartmentController",
  components: {
    selectGraphic,
    selectDepartment,
    selectBranch,
  },
  data() {
    return {
      disableBranch: false,
    };
  },
  created() {},
  computed: {
    ...mapGetters({
      rules: "candidateStep/rules",
      model: "candidateStep/model",
      columns: "candidateStep/columns",
      mode: "MODE"
    }),
  },
  methods: {
    ...mapActions({
      save: "candidateStep/update",
      show: "candidateStep/show",
    }),
    submit(close = true) {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.loadingButton = true;
          this.save(this.form)
            .then((res) => {
              this.loadingButton = false;
              this.$alert(res);
              this.parent().listChanged();
              if (res.status == 201) this.close();
            })
            .catch((err) => {
              this.loadingButton = false;
              this.$alert(err);
            });
        }
      });
    },
    updateBranch(id) {
      if (id) {
        this.form.branch_id = id;
        this.disableBranch = true;
      } else {
        this.disableBranch = false;
      }
    },
  },
};
</script>
